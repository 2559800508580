<template>
	<screen class="checkout" size="large">
		<div
			:style="{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				alignContent: 'center'
			}"
			slot="title"
		>
			<h1 class="checkout-header-title">RoundUp App Checkout Demo</h1>
		</div>
		<div>
			<p class="checkout-header-subtitle">
				Enable RoundUps below to see it in your Order Summary, then
				click "Place Order" to add a record to your test data.
			</p>
		</div>
		<div class="checkout-body roundup-row">
			<div class="checkout-steps">
				<div class="checkout-step roundup-row">
					<p class="checkout-step-icon roundup-row">1</p>
					<div class="checkout-step-body roundup-col">
						<p class="checkout-step-title">Customer</p>
						<div
							style="
								display: flex;
								flex-direction: row;
								align-items: center;
								margin-bottom: 8px;
							"
						>
							<p style="margin-right: 11px; font-weight: bold">
								RoundUp for Charity
							</p>
							<i class="fas fa-info-circle"></i>
						</div>
						<div class="checkout-embed roundup-row">
							<ui-toggle
								@input="toggleRoundUp"
								:checked="roundUp"
							/>
							<div
								style="
									display: flex;
									flex-direction: column;
									width: 100%;
									height: 100%;
									padding-left: 20px;
								"
							>
								<span
									style="
										width: 100%;
										background-color: #333333;
										padding: 5px 9.5px;
										border-radius: 3px;
										margin-bottom: 10px;
										justify-content: flex-start;
									"
									class="roundup-row"
								>
									<p style="color: white">
										We are matching your donations up to
										200%!
									</p>
								</span>
								<span style="margin-bottom: 10px">
									<p>
										I would like to RoundUp my purchase to
										${{ Math.ceil(total).toFixed(2) }} and
										give ${{ roundUpAmount }} to
										{{
											getMerchantInstitutionDetail(
												'name',
												'Humane Society'
											)
										}}, helping them
										{{
											getMerchantInstitutionDetail(
												'call_to_action',
												'make a difference.'
											)
										}}
									</p>
								</span>
								<span>
									<p style="font-size: 10px">
										Your donation is computed excluding tax
										and shipping charges. Visit
										roundupapp.com to learn more about how
										you can RoundUp on every purchase.
									</p>
								</span>
							</div>
						</div>
						<span style="margin-bottom: 20px">
							<p>
								Checking out as a Guest? You'll be able to save
								your details to create an account with us later.
							</p>
						</span>
						<div
							style="
								display: flex;
								flex-direction: row;
								align-items: center;
								width: 100%;
							"
						>
							<input
								class="checkout-input"
								placeholder="Email Address"
								v-model="email"
							/>
							<ui-button class="white border checkout-button">
								Continue as Guest
							</ui-button>
						</div>
					</div>
				</div>
				<div class="checkout-step roundup-row">
					<p class="checkout-step-icon roundup-row">2</p>
					<div class="checkout-step-body roundup-col">
						<p
							class="checkout-step-title"
							style="margin-bottom: 0px"
						>
							Shipping
						</p>
					</div>
				</div>
				<div class="checkout-step roundup-row" style="border: none">
					<p class="checkout-step-icon roundup-row">3</p>
					<div class="checkout-step-body roundup-col">
						<p
							class="checkout-step-title"
							style="margin-bottom: 0px"
						>
							Payment
						</p>
					</div>
				</div>
			</div>
			<div class="checkout-summary">
				<div class="checkout-summary-container">
					<span class="checkout-summary-row">
						<p style="font-weight: bold">Order summary</p>
					</span>
					<span class="checkout-summary-row">
						<div style="margin-bottom: 20px">
							<p>2 items</p>
						</div>
						<div
							style="
								display: flex;
								flex-direction: row;
								justify-content: center;
							"
							:style="{ marginBottom: roundUp ? '20px' : '0px' }"
						>
							<div style="flex: 1; margin-right: 17px">
								<img
									style="height: 60px"
									src="@/assets/checkout_image.png"
								/>
							</div>
							<div style="flex: 3">
								<div style="margin-bottom: 5px">
									<p
										style="
											line-height: 1;
											font-weight: bold;
										"
									>
										2 x Uptown Blazer
									</p>
								</div>
								<span>
									<p>Size: Women's M</p>
								</span>
							</div>
							<div
								style="
									flex: 1;
									line-height: 1;
									text-align: right;
								"
							>
								<p>${{ amount.toFixed(2) }}</p>
							</div>
						</div>
						<div
							v-show="roundUp"
							style="
								display: flex;
								flex-direction: row;
								justify-content: center;
							"
						>
							<div style="flex: 1; margin-right: 17px">
								<img
									style="height: 60px"
									src="@/assets/icon_square.png"
								/>
							</div>
							<div style="flex: 3">
								<div style="margin-bottom: 5px">
									<p
										style="
											line-height: 1;
											font-weight: bold;
										"
									>
										1 x RoundUp Donation
									</p>
								</div>
							</div>
							<div
								style="
									flex: 1;
									line-height: 1;
									text-align: right;
								"
							>
								<p>${{ roundUpAmount }}</p>
							</div>
						</div>
					</span>

					<span class="checkout-summary-row">
						<div
							style="
								display: flex;
								flex-direction: row;
								justify-content: space-between;
								align-items: center;
								margin-bottom: 20px;
							"
						>
							<p>Subtotal</p>
							<p>${{ subtotal }}</p>
						</div>
						<div
							style="
								display: flex;
								flex-direction: row;
								justify-content: space-between;
								align-items: center;
								margin-bottom: 20px;
							"
						>
							<p>Shipping</p>
							<p>${{ shipping }}</p>
						</div>
						<div
							style="
								display: flex;
								flex-direction: row;
								justify-content: space-between;
								align-items: center;
							"
						>
							<p>Tax</p>
							<p>${{ tax.toFixed(2) }}</p>
						</div>
					</span>
					<span class="checkout-summary-row">
						<div
							style="
								display: flex;
								flex-direction: row;
								justify-content: space-between;
								align-items: center;
							"
						>
							<p>Total (USD)</p>
							<p>${{ total }}</p>
						</div>
					</span>
				</div>
				<div style="margin: 0px 20px">
					<ui-button
						@click="onSubmit"
						class="primary"
						style="width: 100%"
						>Place Order</ui-button
					>
				</div>
			</div>
		</div>
	</screen>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import UiInput from '../../ui/Input'
import UiLabel from '../../ui/Label'
import UiToggle from '../../ui/Toggle'
import Modal from '../../ui/Modal'

export default {
	name: 'checkout',
	components: { UiButton, UiInput, UiLabel, Screen, UiToggle, Modal },
	data() {
		return {
			isLoading: false,
			email: '',
			password: '',
			roundUp: false,
			amount: 0,
			tax: 0,
			shipping: 0,
			showInfo: false
		}
	},
	computed: {
		...mapGetters(['merchant', 'institutionDetail']),
		...mapState({
			institution: ({ institutionDetail }) => institutionDetail.item
		}),

		roundUpAmount() {
			const subtotal = 2 * this.amount + this.tax + this.shipping
			const ru = Math.ceil(subtotal) - subtotal
			return (ru === 0 ? 1 : ru).toFixed(2)
		},
		subtotal() {
			const subtotal = 2 * this.amount
			return subtotal.toFixed(2)
		},
		total() {
			const total = 2 * this.amount + this.tax + this.shipping
			if (this.roundUp) {
				return Math.ceil(total).toFixed(2)
			}
			return total.toFixed(2)
		}
	},
	methods: {
		...mapActions(['saveTransaction', 'setDev', 'getMyInstitution']),
		getMerchantInstitutionDetail(key, altText) {
			let detail = altText
			if (this.institution) {
				switch (key) {
					case 'call_to_action':
						if (this.institution.call_to_action) {
							detail = this.institution.call_to_action.toLowerCase()
							detail =
								detail.slice(-1) !== '.' ? detail + '.' : ''
						}

						break
					default:
						detail = this.institution[key] || altText
				}
			}
			return detail
		},
		close() {
			document.body.classList.remove('modal-open')
			this.$router.push({ name: 'reports.donations' })
		},
		toggleRoundUp() {
			this.roundUp = !this.roundUp
		},
		toggleShowInfo() {
			this.showInfo = !this.showInfo
		},
		async onSubmit() {
			if (!this.roundUp) {
				return
			}
			this.isLoading = true

			var dt = new Date().getTime()
			var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
				/[xy]/g,
				function(c) {
					var r = (dt + Math.random() * 16) % 16 | 0
					dt = Math.floor(dt / 16)
					return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
				}
			)

			const body = {
				api_key: this.merchant.dev_api_key,
				transaction_id: uuid,
				name: 'transaction for dog costume',
				amount: this.amount * 2 + this.tax + this.shipping,
				date: new Date()
					.toISOString()
					.replace('T', ' ')
					.replace('Z', '')
			}

			try {
				await this.saveTransaction(body)

				this.isLoading = false
				await this.$swal({
					title: 'Success',
					toast: true,
					position: 'top-end',
					html: `Your RoundUp was successfully tracked and will appear in your test data. <span style="cursor: pointer; color: blue">View RoundUps</span>`,
					showCancelButton: false,
					showConfirmButton: false,
					timer: 5000
				})

				window.localStorage.setItem('demo', false)
				this.$router.push({ name: 'reports.transactions' })
				await this.setDev(true)
			} catch (e) {
				this.isLoading = false
				this.$swal('Error', e)
			}
		}
	},
	mounted() {
		const amount = (
			Math.random() + Math.floor(Math.random() * (150 - 54.99) + 54.99)
		).toFixed(2)
		this.amount = parseFloat(amount)
		this.tax = parseFloat((0.0825 * amount * 2).toFixed(2))
		this.shipping = 4.99
		this.getMyInstitution()
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.checkout {
	height: 100%;
	width: 100%;
	border-radius: 20px;
	.roundup-row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.roundup-col {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	h1,
	h2 {
		line-height: 1.22;
		word-spacing: 5px;
		font-weight: 400;
	}
	p {
		margin: 0px;
		padding: 0px;
		color: $roundup-charcoal;
	}
	.checkout-header {
		height: 100%;
		max-height: 184px;
		width: 100%;
		background-color: $roundup-bone;
		border-radius: 20px 20px 0px 0px;
		padding: 40px 80px 40px 80px;
		border-bottom: 1px solid $roundup-light-grey;
		.checkout-header-title {
			font-size: 2em;
			margin: 0px 0px 20px 0px;
		}
		.checkout-header-subtitle {
			font-size: 1em;
			margin: 0px;
		}
	}
	.checkout-body {
		width: 100%;
		background-color: $white;
		@media screen and (max-width: 1030px) {
			flex-direction: column;
		}
		.checkout-steps {
			flex: 4;
			height: 100%;
			.checkout-step {
				border-bottom: 1px solid $roundup-light-grey;
				margin: 0px 80px 0px 0px;
				padding: 40px 0px;
				.checkout-step-icon {
					background-color: $roundup-slate;
					border-radius: 50px;
					padding: 10px 15px;
					width: 40px;
					height: 40px;
					margin: 0px 20px 0px 0px;
					color: #fff;
					align-self: flex-start;
				}
				.checkout-step-body {
					width: 100%;
					align-items: flex-start;
					.checkout-step-title {
						font-size: 24px;
						color: $roundup-charcoal;
						margin-bottom: 40px;
					}
					.checkout-embed {
						background-color: $roundup-lighter-grey-2;
						padding: 20px;
						border-radius: 5px;
						margin-bottom: 40px;
						border: 1px solid $roundup-light-grey;
					}
					.checkout-input {
						border-radius: 10px;
						border: 1px solid $roundup-slate;
						padding: 10px;
						margin-right: 20px;
						flex: 4;
						outline: none;
						outline-offset: 0px;
					}
					.checkout-button {
						margin-top: 0px;
						height: 100%;
						bottom: 0px;
					}
				}
			}
		}
		.checkout-summary {
			flex: 2;
			align-self: flex-start;
			.checkout-summary-container {
				border: 1px solid $roundup-light-grey;
				box-shadow: 0 7px 10px #9d9d9d;
				margin: 40px 20px 0px 20px;
				border-radius: 10px;
				display: flex;
				flex-direction: column;
				.checkout-summary-row {
					border-bottom: 1px solid $roundup-light-grey;
					padding: 20px 16px;
				}
			}
		}
	}
	.modal-mask-background {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 0;
		left: 0px;
		top: 0px;
	}
	.modal-close {
		background: $roundup-primary;
		position: absolute;
		top: 22px;
		right: 22px;
		padding: 10px 20px;
		text-align: right;
		color: #fff;
		cursor: pointer;
		content: 'Close';
		text-transform: uppercase;
		font-weight: 600;
		font-size: 14px;
		border-width: 0px;
		border-radius: 25px;
		z-index: 999;
		span {
			font-size: 16px;
			vertical-align: middle;
		}
		&:hover {
			background: $roundup-primary;
			color: $white;
		}
	}
	.checkout-title {
		font-weight: 900;
		letter-spacing: 0;
		margin: 10px 0 18px;
	}
	.primary {
		.section-header {
			padding: 1em 20px;
			border-top: 1px solid $roundup-medium-grey;
			border-bottom: 1px solid $roundup-medium-grey;
		}
	}
}
</style>
